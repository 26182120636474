import axios from 'axios'
// export const UserAdminInterceptor = (userIdAdmin?: string, statementId?: string) => {
//   return axios.interceptors.request.use(async (config) => {
//     if (config.data instanceof FormData) {
//       config.data.append('user_id', userIdAdmin as string)
//       config.data.append('statement_id', statementId as string)
//     } else {
//       config.data = {
//         ...config.data,
//         user_id: userIdAdmin,
//         ...(config.url === '/statements/directionTrainings'
//           ? { ['statement']: statementId }
//           : { ['statement_id']: statementId }),
//       }
//     }
//     config.params = {
//       ...config.params,
//       user_id: userIdAdmin,
//       ...(config.url === '/statements/directionTrainings'
//         ? { ['statement']: statementId }
//         : { ['statement_id']: statementId }),
//     }
//     return { ...config }
//   })
// }

export const UserAdminInterceptor = (userIdAdmin?: string, statementId?: string) => {
  return axios.interceptors.request.use((config) => {
    if (config.url === '/olympiads') {
      config.params = {
        ...config.params,
        user_id: userIdAdmin,
      }
      return { ...config }
    }
    if (config.url === '/statements/directionTrainings') {
      if (!config.params.get('user_id')) {
        config.params.append('user_id', `${userIdAdmin}`)
      }
      // config.params = {
      //   ...config.params,
      //   user_id: userIdAdmin,
      //   statement: statementId ?? sessionStorage.getItem('statementId'),
      // }

      return { ...config }
    }
    if (config.data instanceof FormData) {
      config.data.append('user_id', userIdAdmin as string)
      config.data.append(
        'statement_id',
        (statementId ?? sessionStorage.getItem('statementId')) as string
      )
    } else {
      config.data = {
        ...config.data,
        user_id: userIdAdmin,
        ['statement_id']: statementId ?? sessionStorage.getItem('statementId'),
      }
    }
    config.params = {
      ...config.params,
      user_id: userIdAdmin,
      ['statement_id']: statementId,
    }

    return { ...config }
  })
}

export const DeleteInterceptor = (myInterceptor) =>
  axios.interceptors.request.eject(myInterceptor)
