import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import HttpStatusCode from '@common/httpStatusCodes'
import terms from '@common/terms'
import useAppDispatch from '@hooks/useAppDispatch'
import useAppSelector from '@hooks/useAppSelector'
import useFillingStatementsRoutesContext from '@hooks/useFillingStatementsRoutesContext'
import { ViewerTargetContractForm } from '@models'
import { createToast, getPlaceEmploymentsList } from '@redux/action-types'
import {
  selectPlaceEmploymentsList,
  selectStatementsElement,
  selectTargetContract,
} from '@redux/actions/selectors'
import api, {
  AddLgotaEntrantsResponse,
  ResponseOk,
  UploadDocsRespOk,
  UploadDocsResponse,
} from '@services/api'

const useTargetAgreementForm = () => {
  const {
    register,
    setError,
    clearErrors,
    watch,
    control,
    handleSubmit,
    setValue,
    reset,
    ...rest
  } = useForm<ViewerTargetContractForm>({ mode: 'all' })

  const [loading, setLoading] = useState(false)
  const statement = useAppSelector(selectStatementsElement)
  const targetContract = useAppSelector(selectTargetContract)
  const { onNextPageNavigate } = useFillingStatementsRoutesContext()
  const dispatch = useAppDispatch()

  const placeEmploymentsList = useAppSelector(selectPlaceEmploymentsList)

  useEffect(() => {
    !placeEmploymentsList?.length && dispatch(getPlaceEmploymentsList())
  }, [])

  const noTarget = Boolean(watch('noTarget'))

  const isEmployerOrganizationActive = () => {
    let code = placeEmploymentsList?.find(
      (placeEmployment) => placeEmployment.id === Number(watch('placeEmploymentId'))
    )?.code

    return code === '2' || code === '3'
  }

  const fields = {
    noTarget: register('noTarget', {
      onChange: ({ target: { checked } }) => {
        if (checked) {
          reset()
          clearErrors()
          setValue('noTarget', true)
        }
      },
    }),
    organizationName: register('organizationName', {
      required: {
        value: !noTarget,
        message: terms.REQUIRED_FIELD,
      },
    }),
    number: register('number', {
      required: {
        value: !noTarget,
        message: terms.REQUIRED_FIELD,
      },
    }),
    date: register('date', {
      required: {
        value: !noTarget,
        message: terms.REQUIRED_FIELD,
      },
    }),
    monetaryPaymentId: register('monetaryPaymentId', {
      required: terms.REQUIRED_FIELD,
    }),
    paymentForEduId: register('paymentForEduId', {
      required: terms.REQUIRED_FIELD,
    }),
    other: register('other', {
      required: {
        value: !watch('noOther'),
        message: terms.REQUIRED_FIELD,
      },
    }),
    noOther: register('noOther', {
      onChange: () => {
        setValue('other', undefined)
      },
    }),
    belongToSmallPeoples: register('belongToSmallPeoples', {
      required: terms.REQUIRED_FIELD,
    }),
    smallPeopleName: register('smallPeopleName'),
    documents: register('documents', {
      required: {
        value: !noTarget,
        message: terms.REQUIRED_FIELD,
      },
    }),
    farePaymentId: register('farePaymentId', {
      required: terms.REQUIRED_FIELD,
    }),
    housingUsePaymentId: register('housingUsePaymentId', {
      required: terms.REQUIRED_FIELD,
    }),
    totalFunds: register('totalFunds', {
      required: {
        value: !watch('noTotalFunds'),
        message: terms.REQUIRED_FIELD,
      },
    }),
    noTotalFunds: register('noTotalFunds', {
      onChange: () => {
        setValue('totalFunds', undefined)
      },
    }),
    isEmploymentOpk: register('isEmploymentOpk', {
      required: terms.REQUIRED_FIELD,
    }),
    corporationInCharge: register('corporationInCharge', {
      required: terms.REQUIRED_FIELD,
    }),
    placeEmploymentId: register('placeEmploymentId', {
      required: terms.REQUIRED_FIELD,
      onChange: ({ target: { value } }) => {
        if (value === '1') {
          setValue('employerOrganization', '')
        }
      },
    }),
    employerOrganization: register('employerOrganization', {
      required: {
        value: isEmployerOrganizationActive(),
        message: terms.REQUIRED_FIELD,
      },
    }),
    opfOrganization: register('opfOrganization', {
      required: terms.REQUIRED_FIELD,
    }),
    subjectRussiaId: register('subjectRussiaId', {
      required: terms.REQUIRED_FIELD,
    }),
    actualWorkAddress: register('actualWorkAddress', {
      required: terms.REQUIRED_FIELD,
    }),
    economicActivityTypes: register('economicActivityTypes', {
      required: terms.REQUIRED_FIELD,
    }),
  }

  const onSubmit = handleSubmit((data: any) => {
    if (!statement) {
      return
    }
    const normalizedData: ViewerTargetContractForm = {
      ...data,

      statementId: statement.id,
      monetaryPaymentId: data.monetaryPaymentId?.value,
      farePaymentId: data.farePaymentId?.value,
      housingUsePaymentId: data.housingUsePaymentId?.value,
      paymentForEduId: data.paymentForEduId?.value,
      employerLocationId: data.employerLocationId?.value,
      subjectRussiaId: data.subjectRussiaId?.value,
      isCustomerOpk: Boolean(data.isCustomerOpk),
      isEmploymentOpk: data.isEmploymentOpk === '1' ? true : false,
      placeEmploymentId: data.placeEmploymentId,
      employerOrganizationIsCustomer: Boolean(data.employerOrganizationIsCustomer),
      employerIncludedTargetedContract: Boolean(
        data.employerIncludedTargetedContract
      ),
    }

    //догоовра не было, и не добавили
    if (!targetContract && noTarget) {
      onNextPageNavigate()
      return
    }
    // договор был, изменили
    if (targetContract && !noTarget) {
      let status = false
      api.client
        .uploadDocs({
          documents: normalizedData.documents,
          typeDoc: 'TARGET_CONTRACT',
          docId: normalizedData.userDocId,
        })
        .then(() => {
          status = true
        })
        .catch((response: Exclude<UploadDocsResponse, UploadDocsRespOk>) => {
          const { status } = response
          if (status === HttpStatusCode.UNPROCESSABLE_ENTITY) {
            const { data } = response
            dispatch(createToast(data.message, 'danger'))
          } else {
            dispatch(createToast(terms.ERROR_HAS_OCCURED, 'danger'))
          }
        })
        .finally(() => {
          if (status) {
            api.client
              .putTargetContract(normalizedData)
              .then((response) => {
                const { data } = response as unknown as ResponseOk
                dispatch(createToast(data.message, 'success'))
                onNextPageNavigate()
              })
              .catch((response: Exclude<AddLgotaEntrantsResponse, ResponseOk>) => {
                const { status } = response
                if (status === HttpStatusCode.UNPROCESSABLE_ENTITY) {
                  const { data } = response
                  Object.keys(data.errors).forEach((key) => {
                    setError(key as keyof ViewerTargetContractForm, {
                      type: 'manual',
                      message: data.errors[key][0],
                    })
                  })
                  dispatch(createToast(data.message, 'danger'))
                } else {
                  dispatch(createToast(terms.ERROR_HAS_OCCURED, 'danger'))
                }
              })
              .finally(() => setLoading(false))
          }
        })
    }
    //догоовра был, удалили
    if (targetContract && noTarget) {
      api.client
        .deleteTargetContract(statement.id)
        .then((response) => {
          const { data } = response as unknown as ResponseOk
          dispatch(createToast(data.message, 'success'))
          onNextPageNavigate()
        })
        .catch((response: Exclude<AddLgotaEntrantsResponse, ResponseOk>) => {
          const { status } = response
          if (status === HttpStatusCode.UNPROCESSABLE_ENTITY) {
            const { data } = response
            Object.keys(data.errors).forEach((key) => {
              setError(key as keyof ViewerTargetContractForm, {
                type: 'manual',
                message: data.errors[key][0],
              })
            })
            dispatch(createToast(data.message, 'danger'))
          } else {
            dispatch(createToast(terms.ERROR_HAS_OCCURED, 'danger'))
          }
        })
    }

    //догоовра не было, добавили
    if (!targetContract && !noTarget) {
      api.client
        .postTargetContract(normalizedData)
        .then((response) => {
          const { data } = response as unknown as ResponseOk
          dispatch(createToast(data.message, 'success'))
          onNextPageNavigate()
        })
        .catch((response: Exclude<AddLgotaEntrantsResponse, ResponseOk>) => {
          const { status } = response
          if (status === HttpStatusCode.UNPROCESSABLE_ENTITY) {
            const { data } = response
            Object.keys(data.errors).forEach((key) => {
              setError(key as keyof ViewerTargetContractForm, {
                type: 'manual',
                message: data.errors[key][0],
              })
            })
            dispatch(createToast(data.message, 'danger'))
          } else {
            dispatch(createToast(terms.ERROR_HAS_OCCURED, 'danger'))
          }
        })
    }
  })
  return {
    loading,
    noTarget,
    control,
    fields,
    clearErrors,
    onSubmit,
    setValue,
    watch,
    placeEmploymentsList,
    isEmployerOrganizationActive,
    ...rest,
  }
}

export default useTargetAgreementForm
