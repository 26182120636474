import 'pusher-js'
import Echo from 'laravel-echo'

export default (config = {}) => {
  return new Echo({
    broadcaster: 'pusher',
    wsHost: 'herzen.gkomega.ru',
    forceTLS: true,
    wsPort: 6001,
    disableStats: true,
    enabledTransports: ['ws', 'wss'],
    transports: ['websocket', 'polling', 'flashsocket'],
    disabledTransports: ['sockjs', 'xhr_polling', 'xhr_streaming'],
    auth: {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('auth:token')}`,
      },
    },
    ...config,
  })
}
