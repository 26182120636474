import { useEffect } from 'react'

import useAppDispatch from './useAppDispatch'
import useAppSelector from './useAppSelector'
import useInitializeInputs from '@hooks/useInitializeInputs'
import useInitializeSelect from '@hooks/useInitializeSelect'
import usePaginatedSubjectsRussia from '@hooks/usePaginatedSubjectsRussia'
import {
  getFarePaymentsOptions,
  getHousingUsePaymentsOptions,
  getMonetaryPaymentsOptions,
  getPaymentForEdusOptions,
  getTargetContract,
} from '@redux/action-types'
import {
  selectFarePaymentsOptions,
  selectHousingUsePaymentsOptions,
  selectMonetaryPaymentsOptions,
  selectPaymentForEdusOptions,
  selectStatementsElement,
  selectTargetContract,
} from '@redux/actions/selectors'

const useTargetAgreementFormInitialization = ({ setValue, fields }) => {
  const dispatch = useAppDispatch()
  const statement = useAppSelector(selectStatementsElement)

  useEffect(() => {
    if (statement) {
      dispatch(getTargetContract(statement.id))
      !housingUsePaymentsOptions.length && dispatch(getHousingUsePaymentsOptions())
      !paymentForEdusOptions.length && dispatch(getPaymentForEdusOptions())
      !farePaymentsOptions.length && dispatch(getFarePaymentsOptions())
      !monetaryPaymentsOptions.length && dispatch(getMonetaryPaymentsOptions())
    }
  }, [statement])

  const subjectsRussiaSelectProps = usePaginatedSubjectsRussia()
  const housingUsePaymentsOptions = useAppSelector(selectHousingUsePaymentsOptions)
  const paymentForEdusOptions = useAppSelector(selectPaymentForEdusOptions)
  const farePaymentsOptions = useAppSelector(selectFarePaymentsOptions)
  const monetaryPaymentsOptions = useAppSelector(selectMonetaryPaymentsOptions)
  const targetContract = useAppSelector(selectTargetContract)

  useEffect(() => {
    if (!targetContract) {
      Object.keys(fields)?.map((field) => {
        setValue(field, null)
      })

      setValue('noTarget', true)
    } else {
      setValue('noTarget', false)
      setValue('isEmploymentOpk', targetContract.isEmploymentOpk ? '1' : '0')
      setValue(
        'belongToSmallPeoples',
        targetContract.belongToSmallPeoples ? '1' : '0'
      )
    }
  }, [targetContract])

  useInitializeInputs({
    data: targetContract,
    omitKeys: [
      'monetaryPaymentId',
      'farePaymentId',
      'paymentForEduId',
      'subjectRussiaId',
    ],
    onInit: (key, value) => {
      if (key === 'other' && !value) {
        setValue('noOther', true)
      }

      if (key === 'totalFunds' && !value) {
        setValue('noTotalFunds', true)
      }

      setValue(key, value)

      if (key === 'belongToSmallPeoples' && value !== null) {
        setValue('belongToSmallPeoples', value ? '1' : '0')
      }

      if (key === 'placeEmploymentId' && value !== null) {
        setValue('placeEmploymentId', value.toString())
      }

      if (key === 'isEmploymentOpk' && value !== null) {
        setValue('isEmploymentOpk', value ? '1' : '0')
      }
    },
  })

  useInitializeSelect({
    data: targetContract,
    options: monetaryPaymentsOptions,
    findValue: targetContract?.monetaryPaymentId,
    onInit: (option) => setValue('monetaryPaymentId', option as any),
  })

  useInitializeSelect({
    data: targetContract,
    options: farePaymentsOptions,
    findValue: targetContract?.farePaymentId,
    onInit: (option) => setValue('farePaymentId', option as any),
  })

  useInitializeSelect({
    data: targetContract,
    options: housingUsePaymentsOptions,
    findValue: targetContract?.housingUsePaymentId,
    onInit: (option) => setValue('housingUsePaymentId', option as any),
  })

  useInitializeSelect({
    data: targetContract,
    options: paymentForEdusOptions,
    findValue: targetContract?.paymentForEduId,
    onInit: (option) => setValue('paymentForEduId', option as any),
  })

  useInitializeSelect({
    data: targetContract,
    options: subjectsRussiaSelectProps.options,
    findValue: targetContract?.subjectRussiaId,
    onInit: (option) => setValue('subjectRussiaId', option as any),
  })

  return {
    targetContract,
    subjectsRussiaSelectProps,
    housingUsePaymentsOptions,
    paymentForEdusOptions,
    farePaymentsOptions,
    monetaryPaymentsOptions,
  }
}

export default useTargetAgreementFormInitialization
