import { useEffect } from 'react'

import useAppDispatch from './useAppDispatch'
import useAppSelector from './useAppSelector'
import useInitializeInputs from '@hooks/useInitializeInputs'
import useInitializeSelect from '@hooks/useInitializeSelect'
import { SelectOption, ViewerPersonalDataEgeForm } from '@models'
import {
  getCentralExamOptions,
  getDisciplineEgeOptions,
  getPersonalData,
  getTabEge,
  getTabEgeV2,
  getTypeIdentityDocuments,
} from '@redux/action-types'
import {
  selectDisciplineCentralExamOptions,
  selectDisciplineEgeOptions,
  selectPersonalFormData,
  selectStatementsElement,
  selectTabEgeList,
  selectTypeIdentityDocumentOptions,
} from '@redux/actions/selectors'

const useResultExamFormInitialization = ({
  append,
  remove,
  setValue,
  appendPassports,
  removePassports,
}) => {
  const dispatch = useAppDispatch()
  const statement = useAppSelector(selectStatementsElement)

  useEffect(() => {
    if (statement) {
      dispatch(getDisciplineEgeOptions(statement.id))
      dispatch(getCentralExamOptions(statement.id))
      dispatch(getTabEge(statement.id))
      dispatch(getTypeIdentityDocuments())
      dispatch(getPersonalData())
      dispatch(getTabEgeV2(statement.id))
    }
  }, [statement])

  const tabEgeData = useAppSelector(selectTabEgeList)

  const personData = useAppSelector(selectPersonalFormData)
  const personalData = tabEgeData && tabEgeData.personalData
  const personalDataAll = tabEgeData?.personalData
  const resultEge = tabEgeData?.resultEge
  const centralizedTesting = tabEgeData?.centralizedTesting
  const type = tabEgeData?.type

  const disciplineEgeOptions = useAppSelector(selectDisciplineEgeOptions)
  const disciplineCentralExamOptions = useAppSelector(
    selectDisciplineCentralExamOptions
  )
  const typeIdentityDocumentOptions = useAppSelector(
    selectTypeIdentityDocumentOptions
  )
  const now = new Date()
  const year = now.getFullYear()

  const yearOptions: SelectOption[] = [
    { value: +year, label: year + '' },
    { value: year - 1, label: year - 1 + '' },
    { value: year - 2, label: year - 2 + '' },
    { value: year - 3, label: year - 3 + '' },
    { value: year - 4, label: year - 4 + '' },
  ]

  const yearOptionsCE: SelectOption[] = [{ value: +year, label: year + '' }]

  const yearOptionsCT: SelectOption[] = [
    { value: +year, label: year + '' },
    { value: year - 1, label: year - 1 + '' },
  ]

  useInitializeInputs<ViewerPersonalDataEgeForm>({
    data: personalData,
    omitKeys: ['typeIdentityDocumentId'],
    onInit: (key, value) => {
      setValue(key, value)
    },
  })
  useInitializeSelect({
    data: personalData,
    options: typeIdentityDocumentOptions,
    findValue: personalData?.typeIdentityDocumentId,
    onInit: (option) => setValue('typeIdentityDocumentId', option as any),
  })

  useEffect(() => {
    //@ts-ignore
    if (personalData?.noEge || tabEgeData?.noEge) {
      setValue('noEge', true)
    } else {
      setValue('noEge', false)
    }

    resultEge?.forEach((item) => {
      if (item.resultCentralTesting) {
        setValue('centralizedTest', true)
      } else if (item.resultCentralExam) {
        setValue('centralizedExam', true)
      } else {
        setValue('takeEge', true)
      }
    })
  }, [personalData?.noEge, tabEgeData])

  useEffect(() => {
    if (resultEge && disciplineEgeOptions) {
      remove()
      resultEge.map((result) => {
        append(
          {
            disciplineEgeId: disciplineEgeOptions.find(
              (el) => el.value === result.disciplineId
            ),
            year: yearOptions.find((el) => el.label === result.year),
            result: result.result,
            score: result.score,
            verified: result.verified,
            doc: result.doc ? [result.doc] : [],
            doc_id: result.docId,
            resultCentralExam: result.resultCentralExam,
            resultCentralTesting: result.resultCentralTesting,
          },
          { shouldFocus: false }
        )
      })
    }
  }, [resultEge, disciplineEgeOptions])

  useEffect(() => {
    if (personalData && typeIdentityDocumentOptions) {
      removePassports()
      //@ts-ignore
      personalData.map((data, key) => {
        appendPassports({
          typeIdentityDocumentId: typeIdentityDocumentOptions.find(
            (el) => el.value === data.typeIdentityDocumentId
          ),
          name: data.name,
          serial: data.serial,
          surname: data.surname,
          patronymic: data.patronymic,
          number: data.number,
          dateOfIssue: data.dateOfIssue,
          //@ts-ignore
          files: tabEgeData.files,
        })
      })
    }
  }, [personalData])
  return {
    type,
    centralizedTesting,
    yearOptions,
    yearOptionsCE,
    yearOptionsCT,
    typeIdentityDocumentOptions,
    disciplineEgeOptions,
    personData,
    personalData,
    personalDataAll,
    disciplineCentralExamOptions,
  }
}

export default useResultExamFormInitialization
