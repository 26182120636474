import React, { useEffect } from 'react'
import { Controller, FieldError } from 'react-hook-form'
import FillingStatementsForm from '@components/FillingStatementsForm'
import ProfileSection from '@components/ProfileSection'
import useReadOnlyFillingStatements from '@hooks/useReadOnlyFillingStatements'
import useTargetAgreementForm from '@hooks/useTargetAgreementForm'
import useTargetAgreementFormInitialization from '@hooks/useTargetAgreementFormInitialization'
import useUploadDocuments from '@hooks/useUploadDocuments'
import { SelectOption } from '@models'
import Checkbox from '@ui/Checkbox'
import FileUploader from '@ui/FileUploader'
import Input from '@ui/Input'
import Select from '@ui/Select'
import RadioButton from '@ui/RadioButton'
import terms from '@common/terms'

const TargetAgreementForm = () => {
  const {
    noTarget,
    control,
    fields,
    clearErrors,
    formState: { errors },
    onSubmit,
    setValue,
    watch,
    placeEmploymentsList,
    isEmployerOrganizationActive,
  } = useTargetAgreementForm()

  const { readOnly } = useReadOnlyFillingStatements()

  const {
    targetContract,
    subjectsRussiaSelectProps,
    housingUsePaymentsOptions,
    paymentForEdusOptions,
    farePaymentsOptions,
    monetaryPaymentsOptions,
  } = useTargetAgreementFormInitialization({ setValue, fields })

  const { onChange, docs, onDrop, onDelete, docId, getUploadedDoc } =
    useUploadDocuments('TARGET_CONTRACT')

  useEffect(() => {
    if (targetContract?.userDocId) {
      getUploadedDoc(targetContract.userDocId)
    } else {
      getUploadedDoc()
    }
  }, [targetContract?.userDocId])

  useEffect(() => {
    if (docId) {
      setValue('userDocId', docId)
    }
  }, [docId])

  useEffect(() => {
    if (docs.length) {
      clearErrors('documents')
    }
    setValue('documents', docs)
  }, [docs])

  return (
    <FillingStatementsForm
      onSubmit={(e) => {
        e.preventDefault()
        onSubmit()
      }}
    >
      <Checkbox
        disabled={readOnly}
        className={'profile-form__checkbox--mb-24'}
        text={`У меня нет договора о целевом обучении`}
        {...fields.noTarget}
      />

      {!noTarget && (
        <>
          <ProfileSection title={'Данные договора'}>
            <div className="profile-form__fields">
              <Input
                disabled={readOnly}
                className="profile-form__field profile-form__field--fb-100"
                placeholder={'Наименование организации'}
                {...fields.organizationName}
                error={errors.organizationName?.message}
                markRequired
              />

              <Input
                disabled={readOnly}
                className="profile-form__field"
                placeholder={'Номер договора'}
                {...fields.number}
                error={errors.number?.message}
                markRequired
              />

              <Input
                disabled={readOnly}
                className="profile-form__field"
                placeholder={'Дата договора'}
                {...fields.date}
                error={errors.date?.message}
                type={'date'}
                markRequired
              />

              <Input
                disabled={readOnly}
                className="profile-form__field profile-form__field--fb-100"
                markRequired
                placeholder={
                  'Государственный орган, в ведении которого находится организация заказчик'
                }
                {...fields.corporationInCharge}
                error={errors.corporationInCharge?.message}
              />

              <Input
                disabled={readOnly}
                className="profile-form__field profile-form__field--fb-100"
                placeholder={'Организационно-правовая форма организации-заказчика'}
                markRequired
                {...fields.opfOrganization}
                error={errors.opfOrganization?.message}
              />

              <Controller
                name="subjectRussiaId"
                control={control}
                render={({ field }) => (
                  <Select<SelectOption>
                    isDisabled={readOnly}
                    markRequired
                    placeholder={
                      'Субъект РФ, в котором расположена организация-заказчик'
                    }
                    className="profile-form__field profile-form__field--fb-100"
                    {...(field as unknown)}
                    {...subjectsRussiaSelectProps}
                    error={errors.subjectRussiaId?.message}
                  />
                )}
              />
            </div>
          </ProfileSection>

          <ProfileSection
            markRequired={true}
            title={
              'Являюсь лицом из числа коренных малочисленных народов Севера, Сибири и Дальнего Востока'
            }
          >
            <RadioButton
              className="profile-form__radio"
              error={errors.belongToSmallPeoples?.message}
              markRequired
            >
              <RadioButton.Radio
                disabled={readOnly}
                {...fields.belongToSmallPeoples}
                value="1"
                text={terms.YES}
              />

              <RadioButton.Radio
                disabled={readOnly}
                {...fields.belongToSmallPeoples}
                value="0"
                text={terms.NO}
              />
            </RadioButton>
          </ProfileSection>

          <ProfileSection title={'Файл договора'} markRequired fileUploadingHint>
            <FileUploader
              files={docs}
              onChange={onChange}
              onDrop={onDrop}
              onDelete={onDelete}
              className="profile-form__file-uploader"
            />

            {errors.documents && (
              <div className="profile-form__error">
                {(errors.documents as unknown as FieldError | undefined)?.message}
              </div>
            )}
          </ProfileSection>

          <ProfileSection
            title={'Место осуществления трудовой деятельности'}
            markRequired
          >
            <RadioButton
              className="profile-form__radio"
              error={errors.placeEmploymentId?.message}
            >
              {placeEmploymentsList?.map((placeEmployment) => (
                <RadioButton.Radio
                  disabled={readOnly}
                  {...fields.placeEmploymentId}
                  value={placeEmployment.id}
                  key={placeEmployment.id}
                  text={placeEmployment.translates[0].name}
                />
              ))}
            </RadioButton>

            <Input
              disabled={readOnly || !isEmployerOrganizationActive()}
              markRequired={isEmployerOrganizationActive()}
              className="profile-form__field profile-form__field--fb-100"
              placeholder={'Наименование организации-работодателя'}
              {...fields.employerOrganization}
              error={errors.employerOrganization?.message}
            />

            <Input
              disabled={readOnly}
              className="profile-form__field"
              markRequired
              placeholder={'Адрес осуществления трудовой деятельности'}
              {...fields.actualWorkAddress}
              error={errors.actualWorkAddress?.message}
            />

            <Input
              disabled={readOnly}
              className="profile-form__field"
              markRequired
              placeholder={'Вид экономической деятельности организации по ОКВЭД'}
              {...fields.economicActivityTypes}
              error={errors.economicActivityTypes?.message}
            />
          </ProfileSection>

          <ProfileSection
            markRequired={true}
            title={
              'Трудоустройство в организацию ОПК (Оборонно-промышленный комплекс)'
            }
          >
            <RadioButton
              className="profile-form__radio"
              error={errors.isEmploymentOpk?.message}
            >
              <RadioButton.Radio
                disabled={readOnly}
                {...fields.isEmploymentOpk}
                value="1"
                text={terms.YES}
              />

              <RadioButton.Radio
                disabled={readOnly}
                {...fields.isEmploymentOpk}
                value="0"
                text={terms.NO}
              />
            </RadioButton>
          </ProfileSection>

          <ProfileSection title={'Меры поддержки, предусмотренные договором'}>
            <div className="profile-form__fields">
              <Controller
                name="monetaryPaymentId"
                control={control}
                render={({ field }) => (
                  <Select<SelectOption>
                    isDisabled={readOnly}
                    options={monetaryPaymentsOptions}
                    placeholder={'Стипендии и другие денежные выплаты'}
                    markRequired
                    className="profile-form__field"
                    {...(field as unknown)}
                    error={errors.monetaryPaymentId?.message}
                  />
                )}
              />

              <Controller
                name="farePaymentId"
                control={control}
                render={({ field }) => (
                  <Select<SelectOption>
                    isDisabled={readOnly}
                    options={farePaymentsOptions}
                    placeholder={'Оплата питания и (или) проезда'}
                    markRequired
                    className="profile-form__field"
                    {...(field as unknown)}
                    error={errors.farePaymentId?.message}
                  />
                )}
              />

              <Controller
                name="paymentForEduId"
                control={control}
                render={({ field }) => (
                  <Select<SelectOption>
                    isDisabled={readOnly}
                    options={paymentForEdusOptions}
                    placeholder={
                      'Оплата дополнительных платных образовательных услуг, оказываемых за рамками образовательной программы'
                    }
                    markRequired
                    className="profile-form__field profile-form__field--fb-100"
                    {...(field as unknown)}
                    error={errors.paymentForEduId?.message}
                  />
                )}
              />

              <Controller
                name="housingUsePaymentId"
                control={control}
                render={({ field }) => (
                  <Select<SelectOption>
                    isDisabled={readOnly}
                    options={housingUsePaymentsOptions}
                    placeholder={
                      'Предоставление в пользование и (или) оплата жилого помещения в период обучения'
                    }
                    markRequired
                    className="profile-form__field profile-form__field--fb-100"
                    {...(field as unknown)}
                    error={errors.housingUsePaymentId?.message}
                  />
                )}
              />

              <div className="profile-form__field--fb-100">
                <Input
                  disabled={readOnly || watch('noOther')}
                  className="profile-form__field profile-form__field--fb-100"
                  markRequired={!watch('noOther')}
                  {...fields.other}
                  placeholder={'Другие меры'}
                  error={errors.other?.message}
                />

                <Checkbox
                  text="Нет мер"
                  {...fields.noOther}
                  className="check profile-form__checkbox"
                />
              </div>

              <div className="profile-form__field--fb-100">
                <Input
                  disabled={readOnly || watch('noTotalFunds')}
                  className="profile-form__field profile-form__field--fb-100"
                  markRequired={!watch('noTotalFunds')}
                  {...fields.totalFunds}
                  placeholder={'Объем средств материального стимулирования'}
                  error={errors.totalFunds?.message}
                />

                <Checkbox
                  text="Нет мер"
                  {...fields.noTotalFunds}
                  className="check profile-form__checkbox"
                />
              </div>
            </div>
          </ProfileSection>
        </>
      )}
    </FillingStatementsForm>
  )
}

export default TargetAgreementForm
