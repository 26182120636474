import React, { useEffect } from 'react'

import FillingStatementsForm from '@components/FillingStatementsForm'
import useAppDispatch from '@hooks/useAppDispatch'
import useAppSelector from '@hooks/useAppSelector'
import useAttestat from '@hooks/useAttestat'
import useFillingStatementsRoutesContext from '@hooks/useFillingStatementsRoutesContext'
import { getSchoolSubjects } from '@redux/action-types'
import { selectSchoolSubjects, selectUserIdProfile } from '@redux/actions/selectors'
import Input from '@ui/Input'

const CertificatePage = () => {
  const { onNextPageNavigate } = useFillingStatementsRoutesContext()

  const { fields, watch, register, setValue, getValues, onSubmit } = useAttestat()
  const userId = useAppSelector(selectUserIdProfile)

  const schoolSubjects = useAppSelector(selectSchoolSubjects)
  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(getSchoolSubjects())
  }, [])

  useEffect(() => {
    for (let index in schoolSubjects) {
      register(`sendUserSchoolSubjects.data.${Number(index)}.userId`, {
        value: userId,
      })
      register(`sendUserSchoolSubjects.data.${Number(index)}.schoolSubjectsName`, {
        value: schoolSubjects[index].name,
      })
      register(`sendUserSchoolSubjects.data.${Number(index)}.schoolSubjectsId`, {
        value: schoolSubjects[index].id,
      })
      register(`sendUserSchoolSubjects.data.${Number(index)}.estimate`, {
        value:
          getValues(`sendUserSchoolSubjects.data.${Number(index)}.estimate`) ===
          undefined
            ? null
            : getValues(`sendUserSchoolSubjects.data.${Number(index)}.estimate`),
        valueAsNumber: true,
        onChange: (event) => {
          if (event.target.value > 5) {
            setValue(`sendUserSchoolSubjects.data.${Number(index)}.estimate`, 5)
          }

          if (event.target.value < 0) {
            setValue(`sendUserSchoolSubjects.data.${Number(index)}.estimate`, 2)
          }

          if (
            Number(event.target.value) === 0 &&
            event.target.value !== '' &&
            event.target.value !== undefined &&
            event.target.value !== null
          ) {
            setValue(`sendUserSchoolSubjects.data.${Number(index)}.estimate`, 2)
          }

          if (Number(event.target.value) === 1) {
            setValue(`sendUserSchoolSubjects.data.${Number(index)}.estimate`, 2)
          }
        },
      })
    }
  })

  return (
    <div>
      <FillingStatementsForm
        onSubmit={(e) => {
          onSubmit(e)
          e.preventDefault()
          onNextPageNavigate()
        }}
        className="certificate"
      >
        <p className="certificate__description">
          Заполните поля "Количество" для автоматического расчета среднего балла по
          аттестату. Эти поля обязательные для заполнения. В случае, если у вас нет
          той или иной оценки поставьте "0" в поле "Количество"
        </p>

        <div className="certificate__first-block">
          <div className="certificate__average">
            <label htmlFor="3">
              Количество оценок «3» <span style={{ color: 'red' }}>*</span>
            </label>
            <Input id="3" required={true} {...fields.three} type="number" />
          </div>

          <div className="certificate__average">
            <label htmlFor="4">
              Количество оценок «4» <span style={{ color: 'red' }}>*</span>
            </label>
            <Input id="4" required={true} {...fields.four} type="number" />
          </div>

          <div className="certificate__average">
            <label htmlFor="5">
              Количество оценок «5» <span style={{ color: 'red' }}>*</span>
            </label>
            <Input id="5" required={true} {...fields.five} type="number" />
          </div>

          <div className="certificate__average">
            <label htmlFor="average">Средний балл</label>
            <Input id="average" disabled={true} {...fields.average} />
          </div>
        </div>

        <h2 className="certificate__title">Профильные предметы</h2>
        <p className="certificate__second-description">
          Заполните поля ниже, если у вас есть оценки по профильным предметам.
        </p>

        <div className="certificate__second-block">
          {watch('sendUserSchoolSubjects.data')?.map((item, index) => {
            return (
              item && (
                <div key={item.schoolSubjectsId} className="certificate__line">
                  <label htmlFor={item.schoolSubjectsName}>
                    {item.schoolSubjectsName}
                  </label>
                  <Input
                    id={item.schoolSubjectsName}
                    placeholder="Оценка (балл)"
                    type="number"
                    {...register(`sendUserSchoolSubjects.data.${index}.estimate`)}
                  />
                </div>
              )
            )
          })}
        </div>
      </FillingStatementsForm>
    </div>
  )
}

export default CertificatePage
