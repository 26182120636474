const isProduction = process.env.NODE_ENV === 'production'

export const PUBLIC_DOMAIN = isProduction
  ? ~window.location.origin.indexOf('dev')
    ? 'https://dev.app-herzen.gkomega.ru'
    : 'https://herzen.gkomega.ru'
  : 'https://dev.app-herzen.gkomega.ru'

const productionURL = 'https://enrollee.herzen.spb.ru'
const developmentURL = process.env.REACT_APP_DEVELOPMENT_URL || productionURL

export const SERVER_URL = isProduction ? '' : developmentURL
export const SERVER_API_URL = `${SERVER_URL}/back/api`

const CHAT_USE_SSL = process.env.REACT_APP_CHAT_USE_SSL === 'true'
const CHAT_API_SCHEMA = CHAT_USE_SSL ? 'https' : 'http'
const CHAT_SOCKET_SCHEMA = CHAT_USE_SSL ? 'wss' : 'ws'
const CHAT_BASE_URL = process.env.REACT_APP_CHAT_BASE_URL

export const CHAT_API_URL = `${CHAT_API_SCHEMA}://${CHAT_BASE_URL}/api`
export const CHAT_SOCKET_URL = `${CHAT_SOCKET_SCHEMA}://${CHAT_BASE_URL}/socket`
