import React from 'react'

import TargetAgreementForm from '@components/TargetAgreementForm'

const TargetAgreementPage = () => {
  return (
    <div>
      <TargetAgreementForm />
    </div>
  )
}

export default TargetAgreementPage
