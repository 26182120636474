export const columnsStatements = [
  { Header: 'ID', accessor: 'userId' },
  { Header: 'ФИО', accessor: 'fullName' },
  { Header: 'Гражданство', accessor: 'nationality' },
  { Header: 'Модератор', accessor: 'moderator' },
  { Header: 'Сообщения', accessor: 'message' },
  { Header: 'Статус заявления', accessor: 'status' },
  { Header: 'Номер заявления', accessor: 'number' },
  { Header: 'Дата подачи', accessor: 'createDate' },
  { Header: 'Изменено', accessor: 'updateDate' },
  { Header: 'Уровень обр-я', accessor: 'educationLevel' },
  { Header: 'Рекрутинговая компания', accessor: 'partner' },
  { Header: 'Статус договора', accessor: 'contractStatus' },
  { Header: 'Институт', accessor: 'faculty' },
  { Header: 'Направление', accessor: 'direction' },
  { Header: 'Программа', accessor: 'profile' },
  { Header: 'Форма обучения', accessor: 'educationForm' },
  { Header: 'Ист.фин.', accessor: 'formPayment' },
]

export const columnsCoordinatorStatements = [
  { Header: 'ID', accessor: 'userId' },
  { Header: 'ФИО', accessor: 'fullName' },
  { Header: 'Гражданство', accessor: 'nationality' },
  { Header: 'Модератор', accessor: 'moderator' },
  { Header: 'Координатор', accessor: 'coordinator' },
  { Header: 'Сообщения', accessor: 'message' },
  { Header: 'Статус заявления', accessor: 'status' },
  { Header: 'Номер заявления', accessor: 'number' },
  { Header: 'Дата подачи', accessor: 'createDate' },
  { Header: 'Изменено', accessor: 'updateDate' },
  { Header: 'Уровень обр-я', accessor: 'educationLevel' },
  { Header: 'Институт', accessor: 'faculty' },
  { Header: 'Направление', accessor: 'direction' },
  { Header: 'Программа', accessor: 'profile' },
  { Header: 'Форма обучения', accessor: 'educationForm' },
  { Header: 'Ист.фин.', accessor: 'formPayment' },
]

export const columnsContracts = [
  { Header: 'ID', accessor: 'id' },
  { Header: 'ФИО', accessor: 'fullName' },
  { Header: 'Email', accessor: 'email' },
  { Header: 'Направление', accessor: 'direction' },
  { Header: 'Статус заявления', accessor: 'status' },
  { Header: 'Статус договора', accessor: 'statusContract' },
  { Header: 'Модератор', accessor: 'moderator' },
  { Header: 'Партнерская организация', accessor: 'partner' },
]

export const columnsPersonalInformation = [
  { Header: 'ID', accessor: 'idWithPrefix' },
  { Header: 'ФИО', accessor: 'fullName' },
  { Header: 'Email', accessor: 'email' },
  { Header: 'Дата создания аккаунта', accessor: 'createDate' },
  { Header: 'Дата последнего изменения', accessor: 'updateDate' },
  { Header: 'Гражданство', accessor: 'nationality' },
  { Header: 'Вид полученного образования', accessor: 'educationLevel' },
  { Header: 'Непрочитанные сообщения', accessor: 'message' },
]

export const columnsLogList = [
  { Header: 'ID записи', accessor: 'id' },
  { Header: 'ID абитуриента', accessor: 'userId' },
  { Header: 'ФИО модератора', accessor: 'moderatorFullname' },
  { Header: 'ФИО абитуриента', accessor: 'fullNameUser' },
  { Header: 'Дата изменения', accessor: 'updateDate' },
  { Header: 'Cостав изменения', accessor: 'status' },
]

export const columnsAdminDirectoriesList = [
  { Header: 'ID', accessor: 'id' },
  { Header: 'Название', accessor: 'name' },
  { Header: 'Показ', accessor: 'visible' },
  { Header: 'Управление', accessor: 'controls' },
]
