import React, { forwardRef, useState } from 'react'

import classNames from 'classnames'

type CheckboxProps = React.InputHTMLAttributes<HTMLInputElement> & {
  text?: string
  disabled?: boolean
  inputClassName?: string
  theme?: 'default' | 'success-filled'
}

const Checkbox = forwardRef(
  (
    { text, theme = 'default', disabled, inputClassName, ...rest }: CheckboxProps,
    ref: React.Ref<HTMLInputElement>
  ) => {
    const [checked, setValue] = useState(false)
    return (
      <label
        className={classNames(
          'check',
          checked || rest.checked ? 'active' : null,
          disabled ? 'disabled' : null,
          rest.className,
          {
            [`check--theme-${theme}`]: theme != 'default',
          }
        )}
      >
        <input
          type="checkbox"
          {...rest}
          disabled={disabled}
          onClick={() => setValue(!checked)}
          className={classNames('check__input', inputClassName, {
            [`check__input--theme-${theme}`]: theme != 'default',
          })}
          ref={ref}
        />
        <span className="check__box" />
        {text && <span>{text}</span>}
      </label>
    )
  }
)

Checkbox.displayName = 'Checkbox'

export default Checkbox
