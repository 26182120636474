import React, { useEffect, useState } from 'react'
import {
  Widget,
  toggleWidget,
  addResponseMessage,
  addUserMessage,
  deleteMessages,
  setBadgeCount,
  markAllAsRead,
  isWidgetOpened,
  dropMessages,
} from 'react-chat-widget'

import 'react-chat-widget/lib/styles.css'
import axios from 'axios'

import { changeLocale, locale } from '@common/terms'
import { chatMessages } from '@common/ws'
import useAppDispatch from '@hooks/useAppDispatch'
import useAppSelector from '@hooks/useAppSelector'
import useChat from '@hooks/useChat'
import {
  getAdminMessages,
  getUserMessages,
  getAdminNewMessages,
  getUserNewMessages,
} from '@redux/action-types'
import {
  selectAdminMessages,
  selectLocalizedViewerProfile,
  selectStatementsElement,
  selectUserMessages,
} from '@redux/actions/selectors'

type ChatWidgetProps = {
  admin?: boolean
  user?: boolean
  id?: any
}

const ChatWidget = ({ admin, user, id }: ChatWidgetProps) => {
  const {
    addAdminMesages,
    addUserMessages,
    chatId,
    messagesToDelete,
    onRendered,
    adminHandleNewMessage,
    userHandleNewMessage,
  } = useChat(id)

  const dispatch = useAppDispatch()
  const adminMessages = useAppSelector(selectAdminMessages)
  const userMessages = useAppSelector(selectUserMessages)
  const statementEl = useAppSelector(selectStatementsElement)
  const profileData = useAppSelector(selectLocalizedViewerProfile)

  const isForeigner = statementEl?.trajectoryId !== 1

  useEffect(() => {
    if (!id) return

    if (isForeigner) {
      if (isWidgetOpened()) {
        toggleWidget()
      }
    } else {
      toggleWidget()
    }

    admin && dispatch(getAdminMessages(id))
    user && dispatch(getUserMessages())

    admin && chatMessages(id, () => console.log(12))
    user && chatMessages(id, () => {})

    return () => {
      deleteMessages(messagesToDelete)
      setBadgeCount(0)
    }
  }, [id])

  useEffect(() => {
    dropMessages()
    adminMessages && addAdminMesages(adminMessages)
    userMessages && addUserMessages(userMessages)
  }, [adminMessages, userMessages])

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     admin && dispatch(getAdminNewMessages(id))
  //     user && dispatch(getUserNewMessages())
  //   }, 5000)
  //
  //   return () => clearInterval(interval)
  // }, [])

  const title = locale !== 'ru' ? 'Welcome!' : 'Добро пожаловать!'
  const subtitle = locale !== 'ru' ? 'Ask any question' : 'задайте нам вопрос'
  const placeHolderValue =
    locale !== 'ru' ? 'Type a message...' : 'Напишите сообщение...'

  return (
    <Widget
      title={title}
      subtitle={subtitle}
      senderPlaceHolder={placeHolderValue}
      emojis={true}
      handleNewUserMessage={
        admin
          ? adminHandleNewMessage
          : (message) =>
              userHandleNewMessage(
                message,
                statementEl?.statementResponsible[0]?.responsePersonId,
                profileData
              )
      }
      showTimeStamp={false}
      handleToggle={() => {
        if (isWidgetOpened()) {
          onRendered()
        }
      }}
    />
  )
}

export default ChatWidget
