import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import ModeratorDataList from '@components/ModeratorDataList'
import useAppDispatch from '@hooks/useAppDispatch'
import useAppSelector from '@hooks/useAppSelector'
import { DataList, ModeratorTargetContractData } from '@models'
import ModeratorDocumentsPage from '@pages/moderator/ModeratorDocumentsPage'
import { getModeratorStatementsTargetContractData } from '@redux/action-types'
import { selectModeratorStatementsTargetContractData } from '@redux/actions/selectors'
import Checkbox from '@ui/Checkbox'

const ModeratorTargetContractPage = () => {
  const dispatch = useAppDispatch()

  const { id } = useParams<{ id?: string }>()

  const payment = [
    { label: 'нет мер', id: 1 },
    { label: 'менее 15 тыс. руб в год', id: 2 },
    { label: 'от 15 до 30 тыс. руб. в год', id: 3 },
    { label: 'свыше 30 тыс. руб. в год', id: 4 },
  ]

  useEffect(() => {
    if (id) {
      dispatch(getModeratorStatementsTargetContractData({ id: id }))
    }
  }, [id])

  const { data, status }: { data: ModeratorTargetContractData; status: boolean } =
    useAppSelector(selectModeratorStatementsTargetContractData) || {
      data: {},
      status: false,
    }

  const list: DataList = [
    {
      title: 'Наименование организации:',
      label: data?.organizationName || '-',
    },
    {
      title: 'Номер договора:',
      label: data?.number || '-',
    },
    {
      title: 'Дата договора:',
      label: data?.date || '-',
    },
    {
      title: 'Стипендии и другие денежные выплаты:',
      label:
        payment.filter((item) => data?.monetaryPaymentId === item.id)[0]?.label ||
        '-',
    },
    {
      title: 'Оплата образовательных услуг:',
      label:
        payment.filter((item) => data?.paymentForEduId === item.id)[0]?.label || '-',
    },
    {
      title: 'Оплата проезда:',
      label:
        payment.filter((item) => data?.farePaymentId === item.id)[0]?.label || '-',
    },
    {
      title: 'Жилье в пользование а (или) его оплата:',
      label:
        payment.filter((item) => data?.housingUsePaymentId === item.id)[0]?.label ||
        '-',
    },
    {
      title:
        'Суммарный объем средств стимулирования выплачиваемых за учебный год (при наличии):',
      label: data?.totalFunds || '-',
    },
    {
      title:
        'Гос орган (корпорация), в ведении которого находится организация-заказчик:',
      label: data?.corporationInCharge || '-',
    },
    {
      title: 'Организация работодатель:',
      label: data?.employerOrganization || '-',
    },
    {
      title: 'Организационно-правовая форма организации-заказчика:',
      label: data?.opfOrganization || '-',
    },
    {
      title:
        'Субъект РФ, город (иной населенный пункт) в котором расположена организация-заказчик:',
      label: data?.corporationLocation?.translates[0].name || '-',
    },
    {
      title:
        'Субъект РФ, город (иной населенный пункт) в котором расположена организация-работодатель:',
      label: data?.subjectRussia?.translates[0].name || '-',
    },
    {
      title: 'Фактический адрес работы:',
      label: data?.actualWorkAddress || '-',
    },
    {
      title: 'Вид (виды) экономической деятельности по ОКВЭД:',
      label: data?.economicActivityTypes || '-',
    },
    // {
    //   title: 'Должность/профессия/квалификация/вид работ:',
    //   label: data?.position || '-',
    // },
    {
      title: 'Другие меры:',
      label: data?.other || '-',
    },
    {
      title:
        'Являюсь лицом из числа коренных малочисленных народов Севера, Сибири и Дальнего Востока',
      label: data?.belongToSmallPeoples ? 'Да' : 'Нет',
    },
    {
      title: 'Место осуществления трудовой деятельности',
      label: data?.placeEmployment?.translates[0].name || '-',
    },
    {
      title: 'Трудоустройство в организацию ОПК',
      label: data?.isEmploymentOpk ? 'Да' : 'Нет',
    },
  ]

  return (
    <div className={'moderator-data-list'}>
      {status ? (
        <>
          <ModeratorDataList list={list} />
          {/*<Checkbox
            text={'Заказчик организации ОПК'}
            checked={data?.isCustomerOpk || false}
            readOnly
          />
          <Checkbox
            text={'Трудоустройство в организацию ОПК'}
            checked={data?.isEmploymentOpk || false}
            readOnly
          />
          <Checkbox
            text={'Организация-работодатель является заказчиком'}
            checked={data?.employerOrganizationIsCustomer || false}
            readOnly
          />
          <Checkbox
            text={
              'Организация-работодатель, включённая в число сторон договора о целевом обучении'
            }
            checked={data?.employerIncludedTargetedContract || false}
            readOnly
          />*/}
          <ModeratorDocumentsPage
            params={[{ typeDoc: 'target_contract', title: 'Файл договора' }]}
          />
        </>
      ) : (
        <p>Нет целевого договора</p>
      )}
    </div>
  )
}

export default ModeratorTargetContractPage
