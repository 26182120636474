import Echo from 'laravel-echo'

import echo from './echo'

const chechov: Echo = echo({
  key: process.env.REACT_APP_CHAT_APP_KEY,
  authEndpoint: '/back/api/broadcasting/auth',
})

export const chatMessages = (id: number, callback: (data: any) => void) =>
  chechov
    .private(`for_user_${id}`)
    .listen(`new_message`, (data) => console.log(data))

export const notifications = (id: number) =>
  chechov.private(`for_user_${id}`).subscribed((data) => console.log(data))
