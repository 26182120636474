import { NavRoute } from 'types/custom-types'

import terms from '@common/terms'
import useAppSelector from '@hooks/useAppSelector'
import useTypeExamResult from '@hooks/useTypeExamResult'
import { FillingStatementsSectionKey } from '@models'
import { selectLevelValue, selectStatementsElement } from '@redux/actions/selectors'

const useFillingStatements = (scenarioKeys) => {
  const { type } = useTypeExamResult()
  const scenario = useAppSelector(selectLevelValue)

  const fillingStatementsRoutes: NavRoute<FillingStatementsSectionKey>[] = [
    {
      to: 'exam-results',
      title: type === 'ОГЭ' ? terms.BASIC_EXAMS : terms.UNIFRED_EXAMS,
      text: type === 'ОГЭ' ? terms.BASIC_EXAMS_TEXT : terms.UNIFRED_EXAMS_TEXT,
      key: 'examResults',
      status: 'not visited',
    },
    {
      to: 'certificate',
      title: 'Аттестат',
      text: 'Заполните раздел в соответствии с документом об образовании',
      key: 'certificate',
      status: 'not visited',
    },
    {
      to: 'conclusion-base-department',
      title: terms.CONCLUSION_BASE_DEPARTMENT,
      text: '',
      key: 'conclusionBaseDepartment',
      status: 'not visited',
    },
    {
      to: 'achievements',
      title: terms.ACHIEVEMENTS,
      text:
        scenario.id === scenarioKeys.level_training_bachelor_ino.tdId ||
        scenario.id === scenarioKeys.level_training_bachelor.tdId
          ? 'Заполните раздел, если Вы имеете результаты олимпиад, которые могут быть использованы для поступления без вступительных испытаний и (или) зачета 100 баллов по профильному предмету, или индивидуальные достижения, учитываемые при приеме'
          : scenario.id === scenarioKeys.magistra_rf.tdId ||
            scenario.id === scenarioKeys.magistra_ino.tdId
          ? 'Заполните раздел, если Вы имеете результаты олимпиад, которые могут быть приравнены к максимальному количеству баллов по вступительному испытанию, или индивидуальные достижения, учитываемые при приеме'
          : 'Заполните раздел, если Вы имеете результаты олимпиад, которые могут быть использованы для поступления без вступительных испытаний, или индивидуальные достижения, учитываемые при приеме',
      key: 'achievements',
      status: 'not visited',
    },
    {
      to: 'privileges',
      title: terms.PRIORITY_ENROLLMENT,
      text: 'Заполните раздел, если Вы имеете особые права или преимущественное право на поступление (преимущественное право используется только при прочих равных условиях ранжирования)',
      key: 'privileges',
      status: 'not visited',
    },
    {
      to: 'benefitsAndSpecialConditions',
      key: 'benefitsAndSpecialConditions',
      title: 'Льготы и специальные условия для прохождения вступительных испытаний',
      text: 'Заполните раздел, если Вы впервые получили инвалидность I, II или III группы после получения высшего образования или Вам нужны специальные условия для прохождения вступительных испытаний',
      status: 'not visited',
    },
    {
      to: 'target',
      title:
        'Информация об одобренной заявке целевого обучения на портале «Работа России»',
      text: 'Заполните раздел, если у Вас есть договор о целевом обучении',
      key: 'target',
      status: 'not visited',
    },
    {
      to: 'directions',
      title: terms.DIRECTIONS_TRAINING,
      text: '',
      key: 'directions',
      status: 'not visited',
    },
    {
      to: 'directionTrainings',
      title: 'Согласие на зачисление',
      text: 'Подать/отзвать согласие на зачисление',
      key: 'directionTrainings',
      status: 'not visited',
    },
    {
      to: 'directionsPriority',
      title: terms.DIRECTIONS_TRAINING_PRIORITY,
      text: terms.DIRECTIONS_TRAINING_PRIORITY_TEXT,
      hidden: true,
      key: 'directionsPriority',
      status: 'not visited',
    },
    {
      to: 'test',
      title: terms.ENTRANCE_EXAMS,
      text: terms.ENTRANCE_EXAMS_TEXT,
      key: 'test',
      status: 'not visited',
    },
    {
      to: 'contract',
      title: terms.CONTRACT,
      text: terms.CONTRACT_TEXT,
      key: 'contract',
      status: 'not visited',
    },
    {
      to: 'blank',
      title: terms.FORMS_OF_DOCUMENTS,
      text: terms.FORMS_OF_DOCUMENTS_TEXT,
      key: 'blank',
      status: 'not visited',
    },
  ]

  return {
    fillingStatementsRoutes,
  }
}

export default useFillingStatements
